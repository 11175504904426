@media (max-width: 880px){
    .altura-video {
        width: 10px;
    }
}

@media (min-width: 1200px){
    .altura-video {
        height: 400px
    }
}

.div-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    font-weight: 700;
    font-size: 18px;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding: 10px;
    box-shadow: inset 0 0 1em #27645e, 0 0 0.7em #27645e;
    /*display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;
    background-color: #27645e;
    color: #b2d556;
    border-radius: 20px;
    font-weight: 700;
    font-size: 18px;
    box-shadow: inset 0 0 1em #27645e, 0 0 0.7em #27645e;*/
}

.div-card-branco {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    font-weight: 700;
    font-size: 18px;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding: 10px;
    box-shadow: inset 0 0 1em #27645e, 0 0 0.7em #27645e;
    color: white !important;
}

.logo img {
    margin-top: 20%;
    margin-bottom: 10%;
    align-self: center;
  }

  .button-float {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
.h2 {
    padding: 10px;
    color:  #27645e;
    margin-bottom: 15px;
    text-align: center;
}

.header {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
}

.div-buttons {
    width: 80%;
    background-color: #b2d556;
    font-weight: 500;
    color: #27645e;
    border-radius: 50px;
    font-size: 16px;
    border: none;
    font-family: "Arial";
    outline-width: 0;
    margin: 15px;
    cursor: pointer;
  }
  
  .div-buttons:hover{
    width: 80%;
    background-color: #003f1a;
    font-weight: 500;
    color: #b2d556;;
    border-radius: 50px;
    font-size: 16px;
    border: none;
    font-family: "Arial";
    outline-width: 0;
    margin: 15px;
    cursor: pointer;
  }

.container {
    margin: 30px;
}

.login-right { 
    background-color: #27645e;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    padding: 20px;
    width: 500px;
    height: 350px;
}

.link { 
    background-color: #27645e;
    width: 500px;
}

.login-right h2 {
    font-family: 'Roboto Slab';
    font-size: 30px;
    margin-bottom: 20px;
}

.login-right h4 {
    font-family: 'Roboto Slab';
    font-weight: 300;
    margin-top: 40px;
    font-size: 15px;
    font-weight: 500;
    color: #e1e1e6;
}

.login-loginInputEmail {
    display: flex;
    align-items: center;
    color: gray;
    background-color: white;
    border-radius: 5px;
    padding: 3px;
    width: 98%;
    height: 50px;
    margin: 20px;
}

.login-loginInputEmail svg {
    margin-left: 10px;
    font-size: 25px;
}

.login-loginInputEmail input {
    background: transparent;
    width: 100%;
    outline-width: 0;
    color:  black;
    border: none;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
}

.login-loginInputPassword {
    display: flex;
    align-items: center;
    color: gray;
    background-color: white;
    border-radius: 3px;
    padding: 3px;
    margin: 5px;
    width: 98%;
    height: 50px;
    border-radius: 5px;
    margin: 10px;
}

.login-loginInputPassword svg {
    margin-left: 10px;
    font-size: 25px;
}

.login-loginInputPassword input {
    background: transparent;
    width: 100%;
    outline-width: 0;
    color:  black;
    border: none;
    font-size: 17px;
    margin-left: 12px;
    margin-right: 10px;
}

.button-icon {
    cursor: pointer;
    background-color: #27645e;
    color: #b2d556;
    padding: 5px;
    border-radius: 50px;
  }

.link button {
    width: 100%;
    background-color: #b2d556;
    color: #27645e;
    font-weight: 800;
    height: 50px;
    border-radius: 5px;
    font-size: 18px;
    margin-top: 30px;
    border: none;
    outline-width: 0;
}

.link button:hover {
    background-color: #003f1a;
    color: #b2d556;
    cursor: pointer;
}

.login-eye {
    align-items: center;
    justify-content: center;
    font-size: 30;
    cursor: pointer;
    margin-right: 10px;
}