@media (max-width: 880px){
    .login {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: white;
        margin-top: 0px;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .login-logo img {
        margin-bottom: 50px;
        margin-top: 100px;
    }
    .login-right { 
        background-color: #168f59;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        padding: 20px;
        width: 70%;
        height: 100%;
        margin: 0;
    }
  }
  
  @media (min-width: 1200px){
    .login {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: white;
        height: 100vh;
    }
    .login-logo img {
        margin-bottom: 50px;
    }
    .login-right { 
        background-color: #168f59;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        height: 350px;
        margin: 0;
        box-shadow: inset 0 0 1em #168f59, 0 0 1em #168f59;
    }
  }
  
  .link { 
    background-color: #168f59;
    width: 500px;
  }
  
  .login-right h2 {
    font-family: 'Roboto Slab';
    font-size: 30px;
    margin-bottom: 20px;
  }
  
  .login-right h4 {
    font-family: 'Roboto Slab';
    font-weight: 300;
    margin-top: 40px;
    font-size: 15px;
    font-weight: 500;
    color: #e1e1e6;
  }
  
  .login-loginInputEmail {
    display: flex;
    align-items: center;
    color: gray;
    background-color: white;
    border-radius: 5px;
    padding: 3px;
    width: 98%;
    height: 50px;
    margin: 20px;
  }
  
  .login-loginInputEmail svg {
    margin-left: 10px;
    font-size: 25px;
  }
  
  .login-loginInputEmail input {
    background: transparent;
    width: 100%;
    outline-width: 0;
    color:  black;
    border: none;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .login-loginInputPassword {
    display: flex;
    align-items: center;
    color: gray;
    background-color: white;
    border-radius: 3px;
    padding: 3px;
    margin: 5px;
    width: 98%;
    height: 50px;
    border-radius: 5px;
    margin: 10px;
  }
  
  .login-loginInputPassword svg {
    margin-left: 10px;
    font-size: 25px;
  }
  
  .login-loginInputPassword input {
    background: transparent;
    width: 100%;
    outline-width: 0;
    color:  black;
    border: none;
    font-size: 17px;
    margin-left: 12px;
    margin-right: 10px;
  }
  
  .login-right button {
    width: 100%;
    background-color: #003f1a;
    color: #b2d556;
    font-weight: 800;
    height: 50px;
    border-radius: 5px;
    font-size: 18px;
    margin-top: 30px;
    border: none;
    outline-width: 0;
  }
  
  .login-right button:hover {
    background-color: #b2d556;
    color: #003f1a;
    cursor: pointer;
  }
  
  .login-eye {
    align-items: center;
    justify-content: center;
    font-size: 30;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .title{
    padding: 10px;
    color: white;
    font-size: 25px;
    display: flex;
    text-align: center;
  }
  
  h2 {
    color: #003f1a;
  }